import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Menü | Menü Fesulkin
			</title>
			<meta name={"description"} content={"Fesulkin'da spor pub deneyiminizi yükseltmek için hazırlanmış kışkırtıcı menümüzle lezzetin tadını çıkarın. Maç günü yemeklerinden tatlı zaferlere kadar her ısırık ve yudum bir kutlamadır. "} />
			<meta property={"og:title"} content={"Menü | Menü Fesulkin"} />
			<meta property={"og:description"} content={"Fesulkin'da spor pub deneyiminizi yükseltmek için hazırlanmış kışkırtıcı menümüzle lezzetin tadını çıkarın. Maç günü yemeklerinden tatlı zaferlere kadar her ısırık ve yudum bir kutlamadır. "} />
			<meta property={"og:image"} content={"https://fesulkin.com/img/220221_PeterRentschler_PR4_4570-2-1024x682.jpg"} />
			<link rel={"shortcut icon"} href={"https://fesulkin.com/img/3285412.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fesulkin.com/img/3285412.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fesulkin.com/img/3285412.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fesulkin.com/img/3285412.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fesulkin.com/img/3285412.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fesulkin.com/img/3285412.png"} />
			<meta name={"msapplication-TileColor"} content={"https://fesulkin.com/img/3285412.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Menü
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Fesulkin'da spor pub deneyiminizi yükseltmek için hazırlanmış kışkırtıcı menümüzle lezzetin tadını çıkarın. Maç günü yemeklerinden tatlı zaferlere kadar her ısırık ve yudum bir kutlamadır. Bu menü sadece bir bakış - sizi bekleyen bir mutfak lezzetleri dünyası var. Diyetle ilgili endişeleriniz veya özel tercihleriniz mi var? Ekibimize ulaşın ve ziyaretinizin sadece unutulmaz değil, aynı zamanda zevkinize göre mükemmel bir şekilde uyarlanmış olmasını sağlayalım.
			</Text>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://fesulkin.com/img/aasduetktgg.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Zanaat Bira Seçkisi:
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					- Local Legends Lager: Gevrek ve ferahlatıcı, yerel bir favori.
					<br />
					- Hoppy Hour IPA: Cesur ve acı, bir şerbetçiotu aşığının keyfi.
					<br />
					- Goalpost Pilsner: Hafif ve kolay içimli, mükemmel bir maç günü tercihi.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Brewmaster'ın Seçtikleri:
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					- Brewmaster's Flight: En iyi tadım deneyimi için usta biracımızın en iyi seçimlerinden bir seçki.
					<br />
					- Signature Stout: Biranın karanlık tarafının tadını çıkaranlar için mükemmel, zengin ve sağlam bir bira.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Oyun Günü Yiyecekleri:
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					- Slam Dunk Nachos: Eritilmiş peynir, jalapeños ve tuzlu kıymanın kazanan kombinasyonuyla dolu.
					<br />
					- End Zone Wings: Lezzet patlaması için baharatlı bufalo sosundan keskin barbeküye kadar özel soslarımızdan birini seçin.
				</Text>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Brews & Bites Kombinasyonları:
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					- Craft Beer Sliders: En iyi butik biralarımızla eşleştirilmiş mini burgerler - Fesulkin cennetinde yapılmış bir eşleşme.
					<br />
					- Brewmaster'ın Tabağı: Yoğun maçlar sırasında paylaşmak için mükemmel olan el yapımı mezelerden oluşan cömert bir ürün yelpazesi.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Muzaffer Vejetaryen:
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					- Bira Peynirli Pretzel Knots: Yumuşak simit düğümleri, geleneksel olana bir değişiklik katmak için kremalı bira peyniri ile servis edilir.
					<br />
					- Shuffleboard Sebze Şişleri: En sevdiğimiz shuffleboard oyunundan esinlenerek şişte servis edilen ızgara sebzeler.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Tatlı Zaferler:
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					- Grand Slam Brownie Sundae: Vanilyalı dondurma toplarıyla taçlandırılmış, çikolata sosuyla süslenmiş çökmekte olan bir brownie.
				</Text>
				<Link
					href="/contact-us"
					color="--primary"
					font="--lead"
					text-decoration-line="initial"
					hover-text-decoration-line="underline"
				>
					bizi ziyaret edin
				</Link>
			</Box>
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://fesulkin.com/img/ddk4k534.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
		</Section>
		<Components.Images />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});